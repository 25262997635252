/**
 * JS for Rhapsody
 */

var $, jQuery = window.jQuery;

/**
 * Log function
 *
 * usage: log('inside coolFunc',this,arguments);
 * source: http://paulirish.com/2009/log-a-lightweight-wrapper-for-consolelog/
 */
window.log = function(){
  log.history = log.history || [];   // store logs to an array for reference
  log.history.push(arguments);
  if(this.console){
    console.log( Array.prototype.slice.call(arguments) );
  }
};

$(function() {

  "use strict";

  var breakpoint = 1024;

  var supportsOrientationChange = 'onorientationchange' in window,
      orientationEvent = supportsOrientationChange ? 'orientationchange' : 'resize';

  /**
   *   Returns a function, that, as long as it continues to be invoked, will not
   *   be triggered. The function will be called after it stops being called for
   *   N milliseconds. If `immediate` is passed, trigger the function on the
   *   leading edge, instead of the trailing. Copied from underscores.js.
   */
  function debounce(func, wait, immediate) {
    var timeout;
    return function() {
      var context = this, args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  function parallaxHome() {
    if($('#hero__home').length && $(window).outerWidth() > breakpoint) {
      var width = $(window).outerWidth();
      var height = $(window).outerHeight();
      $('#hero__home').css({
        'width': width,
        'height': (height * .75)
      }).parallax("50%", 0.2);
    } else {
      $('#hero__home').css({
        'width': '',
        'height': ''
      })
    }
  }

  function parallaxPage() {
    if($('#hero').length && $(window).outerWidth() > breakpoint) {
      var width = $(window).outerWidth();
      var height = $(window).outerHeight();
      $('#hero').css({
        'width': width,
        'height': (height / 1.6)
      }).parallax("50%", 0.2);
    } else {
      $('#hero').css({
        'width': '',
        'height': ''
      })
    }
  }

  function parallaxFWTeaser() {
    if($('.teaser-fw__img').length && $(window).outerWidth() > breakpoint ) {
      var width = $(window).outerWidth();
      $('.teaser-fw__img').css({
        'width': width
      }).parallax("50%", 0.2);
    } else {
      $('#hero').css({
        'width': ''
      })
    }
  }

  function homeGallery() {
    if($('.home-gallery__items').length) {
      $('.home-gallery__items').magnificPopup({
        delegate: 'a',
        type: 'image',
        tLoading: 'Loading image #%curr%...',
        mainClass: 'mfp-img-mobile',
        gallery: {
          enabled: true,
          navigateByImgClick: true,
          preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
        },
        image: {
          tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
          titleSrc: function (item) {
            return item.el.attr('title');
          }
        }
      });
    }
  }

  var homeSlider = $('.slider__inner');

  if( homeSlider.length ) {
    homeSlider.slick({
      autoplay: true,
      dots: true,
      arrows: false,
      infinite: true,
      speed: 350,
      fade: true,
      cssEase: 'linear',
      pauseOnHover: false
    });
  }

  var gallerySlider = $('.event-gallery');

  if( gallerySlider.length ) {
    gallerySlider.slick({
      autoplay: false,
      dots: false,
      arrows: true,
      infinite: true,
      cssEase: 'ease-in-out',
      centerMode: true,
      centerPadding: '40px',
      variableWidth: true,
      slidesToShow: 1,
      speed: 400
    });
  }

  var buzzOpen = $('.buzz__open')
    , buzzGallery = $('.buzz__gallery');

  if( buzzOpen.length ) {

    buzzOpen.on('click', function () {
      buzzGallery.magnificPopup('open');
    });
    buzzGallery.each(function () {

      $(this).magnificPopup({
        delegate: 'a',
        type: 'image',
        gallery: {
          enabled: true,
        },
        fixedContentPos: false
      });
    });
  }

  $('#nav-open').on('click', function() {
    $('body').addClass('js-nav');
  });

  $('#nav-close').on('click', function() {
    $('body').removeClass('js-nav');
  });

  new WOW().init();
  homeGallery();

  $(window).on(orientationEvent,  debounce(function() {

    parallaxHome();
    parallaxPage();
    parallaxFWTeaser();
    // homeMasonry();

  }, 250)).trigger(orientationEvent);

}(jQuery));



